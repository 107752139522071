import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ContactService } from '../contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private contacter: ContactService) { }

  form: FormGroup;
  showError: boolean;
  isMessageSent: boolean;

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required]
      }),

      email: new FormControl(null, {
        validators: [Validators.required, Validators.email]
      }),

      message: new FormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  onClick() {
    if (!this.form.value.name || !this.form.value.email || !this.form.value.message) {
      this.showError = true;
      this.isMessageSent = false;
    } else {
      this.showError = false;
      this.isMessageSent = true;
      this.contacter.contactMe(this.form.value.name, this.form.value.email, this.form.value.message);
      console.log(this.form.value.name);
    }
  }

}
