import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor() { }

  public typed;

  ngOnInit() {
    this.typed = new Typed('.roller', {
      strings: ['programmer', 'ui/ux designer', 'full stack web developer'],
      typeSpeed: 50,
      showCursor: false,
      loop: true
    });
  }

}
