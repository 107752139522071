import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({ providedIn: "root" })
export class ContactService {
  constructor(private http: HttpClient) {}

  contactMe(nameToSend: any, emailToSend: string, messageToSend: string) {
    // const contactData = new FormData();
    // contactData.append('name', nameToSend);
    // contactData.append('email', emailToSend);
    // contactData.append('message', messageToSend);

    const contactData = {
      name: nameToSend,
      email: emailToSend,
      message: messageToSend,
    };

    console.log(contactData);

    this.http
      .post<{ message: string }>(environment.SENGRID_API_URL, contactData)
      .subscribe((result) => {
        console.log(result.message);
      });
  }
}
