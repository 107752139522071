<nav class="navbar">
  <a class="navbar-brand" href="#">nishsatish</a>

  <a href="#"class="menu-toggler float-right" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false">
    <div class="menu-bar menu-bar1"></div>
    <div class="menu-bar menu-bar2"></div>
    <div class="menu-bar menu-bar3"></div>
  </a>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav flex-row ml-md-auto d-md-flex float-right">
      <li class="nav-item active">
        <a class="nav-link" href="#about">About <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#skills" (click)="onLinkClick()">Skills </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#work" tabindex="-1" aria-disabled="true">work</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#contact" tabindex="-1" aria-disabled="true">contact</a>
      </li>
    </ul>
  </div>
</nav>
