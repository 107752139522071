<div class="skills container-fluid" #skillsSection>
  <div class="row">
    <div class="col-lg">
      <img src="./assets/img/MYBACK2.svg" alt="" />
    </div>
    <div class="col-lg">
      <h1 [ngClass]="classes">skill stats</h1>
      <div class="skills-content">
        <div class="skillbar skill3">
          <span class="content-head">node.js & express</span>
          <div class="prog-out">
            <div class="prog-in long-skill2" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar skill5">
          <span class="content-head">php & laravel</span>
          <div class="prog-out">
            <div class="prog-in" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar skill6">
          <span class="content-head content-head-right">mongo db/postgres</span>
          <div class="prog-out">
            <div class="prog-in" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar">
          <span class="content-head content-head-right">graphql & rest</span>
          <div class="prog-out">
            <div class="prog-in" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar skill3">
          <span class="content-head">angular & typescript</span>
          <div class="prog-out">
            <div class="prog-in long-skill1" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar skill2">
          <span class="content-head">HTML/CSS/JS</span>
          <div class="prog-out">
            <div class="prog-in" [style.width]="width"></div>
          </div>
        </div>

        <div class="skillbar skill1">
          <span class="content-head">UI/UX & graphic design</span>
          <div class="prog-out">
            <div class="prog-in" [style.width]="width"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
