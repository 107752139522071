<div class="footer-page">
  <div class="links-footer text-center">
    <a href="#about">about me</a>
    <a href="#skills">skills</a>
    <a href="#contact">contact</a>
  </div><br>
  <div class="profile-links text-center">
    <a href="https://twitter.com/nishsatish" target="_blank"><i class="fab fa-twitter"></i></a>
    <a href="https://github.com/nishsatish" target="_blank"><i class="fab fa-github"></i></a>
    <a href="https://dribbble.com/nishsatish" target="_blank"><i class="fab fa-dribbble"></i></a>
    <a href="https://instagram.com/nishsatish" target="_blank"><i class="fab fa-instagram"></i></a>
  </div><br><br>
  <div class="brand text-center">
    template designed by <a href="#" class="life-changer">nishsatish</a>
  </div>
</div>
