import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor() { }

  onLinkClick() {
    document.querySelector('#skills').scrollIntoView({behavior: 'smooth'});
  }

  ngOnInit() {
  }

}
