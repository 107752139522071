<div class="about container-fluid">
  <div class="row">
    <div class="col-lg">
      <h1 class="about-me">About Me</h1>
      <p class="bio">
        Hello there! I'm Nishant Satish, a full-stack web developer. I live in
        Bangalore, India and can speak fluent English and native Indian
        languages.<br />

        My goal is to bring your ideas to life. My skills include HTML5, CSS3,
        JavaScript, PHP and various frameworks like Angular and Laravel.
        <br /><br />

        I am a person who believes that the result is good when there is some
        effort going into whatever you're doing. I love my job as a developer,
        and I am ready to put that extra effort that makes my result better than
        others'. I am a full-stack developer, and as a designer I use the best
        libraries and frameworks that help your website get that modern, stylish
        finish. As a developer I make sure that the functionality is well
        structured , simple and of course, functional. <br />

        Need a website professionally done? You've come to the right place!
      </p>
    </div>
    <div class="col-lg text-right">
      <img src="./assets/img/MYBACK.svg" alt="" />
    </div>
  </div>
</div>
