import { Component, OnInit, HostListener, ViewChild, Inject } from '@angular/core';
import { WINDOW } from '../window.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window) { }

  @ViewChild('progresso') bars;

  shouldHide = true;
  width = '0px';
  transitionTime: any;
  public number: number; // The percentage
  public statConfig: any; // Its configuration
  public triggerStat: boolean; // Trigger for the animation
  classes = 'about-me text-right';
  public innerWidth: any;
  public headingWidth: any;

  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const scrollNo = this.window.pageYOffset || 0;
    this.innerWidth = window.innerWidth;

    // Responsive Scrolling Animation
    if (this.innerWidth <= 414) {
      if (scrollNo > 2100) {
        this.width = '97%';
      }

      if (scrollNo < 2000) {
        this.width = '0px';
      }
    } else {
      if (scrollNo > 1401) {
        this.width = '97%';
      }

      if (scrollNo < 1300) {
        this.width = '0px';
      }
    }

    if (this.innerWidth <= 414) {
      this.classes = 'about-me';
    } else {
      this.classes = 'about-me text-right';
    }
  }

  ngOnInit() {
    this.transitionTime = '500ms';
  }

}
