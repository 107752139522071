<div class="work-section">
  <h1 class="work-title text-left">my work</h1>
  <div class="work-caption">Here's a collection of some of my personal projects and open source work.</div>
  <span class="mobile-only">Click to view more!</span>
  <!-- <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="j" *ngFor="let dumis of dummyData; index as j" [attr.data-index]="j" [class]="j>0 ? '' : 'active'"></li>
    </ol>
    <div class="carousel-inner">
      <div [class]="i > 3 ? 'carousel-item active' : 'carousel-item'" *ngFor="let dums of dummyData; index as i; $implicit as iKaValue" [attr.data-index]="i">
          <div class="card" id="card">
            <div class="card-body">
              <div class="hover-me text-center">view on github</div>
              <h4 class="card-title text-center"><span>{{dums.title}}</span></h4>
              <hr><br>
              <div class="card-text text-center"><span>{{dums.text}}</span></div>
            </div>
          </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> -->

  <div class="work-gallery">
    <div class="row">
      <div class="work-box col-md-4 text-center" *ngFor="let work of graphTest2?.nodes; index as i">
        <div class="work-inner hvr-grow">
          <div class="work-name">{{work.name}}</div>
          <hr>
          <div class="work-text hover-me1">{{work.description}}</div>

          <a class="btn hover-me2" href="{{work.url}}" target="_blank">view project</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Additional Links -->
  <div class="more-profiles text-center">
    <span class="also-on">Also view on</span> <br>
    <a href="http://github.com/nishsatish" target="_blank"><i class="fab fa-github"></i></a>
    <a href="http://dribbble.com/nishsatish" target="_blank"><i class="fab fa-dribbble"></i></a>
  </div>
</div>
