<h1 class="contact-title">get your website today!</h1>
<div class="contact-form">
  <form [formGroup]="form">
    <div class="form-group">
      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" formControlName="name" style="height: 60px;">
    </div>
    <div class="form-group">
      <input type="email" class="form-control" height="100" formControlName="email" placeholder="E-Mail" style="height: 60px;">
    </div>
    <div class="form-group">
      <input type="text" class="form-control" id="exampleInputPassword2" formControlName="message" placeholder="leave a message..." style="height: 150px;">
    </div>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-164056989-1"></script>
    <button type="submit" class="" (click)="onClick()" onclick="gtag('event', 'hitContact', {
      event_category: 'formsubmissions',
      event_label: 'formSubmission'
    }); console.log('mamma')"><span class="send">send</span><span class="send-mobile">send</span></button>
  </form>
  <div class="error-message" *ngIf="showError">
    <i class="fas fa-exclamation-circle"></i> Please fill out all details
  </div>
  <div class="error-message success-msg" *ngIf="isMessageSent">
    <i class="fas fa-check-circle"></i> Message Sent!
  </div>
</div>

<div class="more-contact text-center">
  <span class="also-at">Follow my work on</span><br>
  <a href="http://twitter.com/nishsatish" target="_blank"><i class="fab fa-twitter"></i></a>
  <a href="http://instagram.com/nishsatish" target="_blank"><i class="fab fa-instagram"></i></a>
</div>
