import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  constructor(private apollo: Apollo) { }

  public dummyData = [
    {
      title: '50 Cent',
      text: 'As a student, earning lots of money is not my goal. '
    },
    {
      title: 'Dr. Dre',
      text: 'As a student, earning lots of money is not my goal. My '
    },
    {
      title: 'Eminem',
      // tslint:disable-next-line: max-line-length
      text: 'As a student, earning lots of money is not my goal. My '
    },
    {
      title: '2pac',
      text: 'As a student, earning lots of money is not my goal. My '
    },
    {
      title: 'The Notorious B.I.G',
      text: 'As a student, earning lots of money is not my goal. My '
    }
  ];

  public classes;
  public hasClass: boolean;

  graphTest1: any;
  graphTest2: any;
  data: Observable<any>;

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          query {
            viewer {
              repositories(first: 9, isFork: false, orderBy: {field: CREATED_AT, direction: DESC}) {
                nodes {
                  name
                  description
                  url
                }
              }
            }
          }
        `
      })
      .valueChanges
      .subscribe(result => {
        this.graphTest1 = result;
        this.graphTest2 = this.graphTest1.data.viewer.repositories;
      });

  }

}
