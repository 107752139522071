<div class="stats">
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-md stat1">
        <ng2-odometer [number]="number1" [config]="statConfig1" style="color: #707070;"></ng2-odometer><span class="plus">+</span><br>
        <span class="what-stat">years</span>
      </div>
      <div class="col-md stat2">
        <ng2-odometer [number]="number2" [config]="statConfig2" style="color: #707070;"></ng2-odometer><span class="plus">+</span><br>
        <span class="what-stat">projects</span>
      </div>
      <div class="col-md stat3">
        <ng2-odometer [number]="number3" [config]="statConfig3" style="color: #707070;"><span></span></ng2-odometer><span class="plus">+</span><br>
        <span class="what-stat">certifications</span>
      </div>
    </div>
  </div>
</div>
