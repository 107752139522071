import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { WINDOW } from '../window.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window) { }

  public number1;
  public number2;
  public number3;
  public statConfig1: any;
  statConfig2: any;
  statConfig3: any; // Its configuration
  public triggerStat: boolean; // Trigger for the animation

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollNo = this.window.pageYOffset || 0;
    if (scrollNo > 100) {
      this.number1 = '3';
      this.number2 = '10';
      this.number3 = '20';
    }
  }

  ngOnInit() {
    this.statConfig1 = {
      animation: 'count',
      theme: 'default',
      auto: 'false'
    };

    this.statConfig2 = {
      animation: 'count',
      theme: 'default',
      auto: 'false'
    };

    this.statConfig3 = {
      animation: 'count',
      theme: 'default',
      auto: 'false'
    };
  }

}
